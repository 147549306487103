<template>
	<div class="wrap">
		<myHead></myHead>
		<div class="wrap_body">
			<div class="center">
				<div class="ucenter_main">
					<ucMenu num="2"></ucMenu>
					<div class="right_con">
						<div class="goods_main" v-loading="isLoading">
              <p class="fb">基本配置</p>
              <div class="select_wrap" style="margin-top: 0">

                <el-input class="w240" v-model="basicConfig.day" placeholder="请输入内容">
                  <p slot="prefix" class="label_title">日销量</p>
                </el-input>
                <el-input class="w240" v-model="basicConfig.week" placeholder="请输入内容">
                  <p slot="prefix" class="label_title">周销量</p>
                </el-input>
                <el-input class="w240" v-model="basicConfig.month" placeholder="请输入内容">
                  <p slot="prefix" class="label_title">月销量</p>
                </el-input>
                <el-button plain size="small" @click="saveBasicConfig()">设置</el-button>

              </div>
							<p class="fb title_fb" >商品中心</p>
							<div class="select_wrap"  style="margin-top: 0">
								<!-- <el-select class="w290" v-model="searchData.shop_type" placeholder="请选择" clearable filterable >
									<p slot="prefix" class="label_title">店铺类型</p>
									<el-option v-for="item in shopTypeList" :key="item.val" :label="item.text" :value="item.val">
									</el-option>
								</el-select> -->
								<el-select class="w240" v-model="searchData.dangkou_id" placeholder="请选择" clearable filterable >
									<p slot="prefix" class="label_title">档口</p>
									<el-option v-for="item in dangkouList" :key="item.dangkou_id" :label="item.full_txt" :value="item.dangkou_id">
									</el-option>
								</el-select>


								
								<el-input class="w240" v-model="searchData.goods_no" placeholder="请输入内容">
									<p slot="prefix" class="label_title">货号</p>
								</el-input>
                <el-select class="w240" v-model="searchData.dicker_status" placeholder="请选择" clearable>
                  <p slot="prefix" class="label_title">谈价状态</p>
                  <el-option label="未谈" :value="1"></el-option>
                  <el-option label="已谈" :value="2"></el-option>
                </el-select>
								<el-button plain size="small" @click="getList()">查询</el-button>
								<el-button plain size="small" @click="resetSearch()">重置</el-button>
							</div>
<!--							<div class="tabs_wrap">-->
<!--								<p class="tab" :class="state==0?'active':''" @click="bindSate(0)">全部商品</p>-->
<!--							</div>-->
							<div class="totals_btn">
								<p class="totals">共{{page_data.total}}条</p>

							</div>
							<div class="list_title">
								<label class="checkall">
<!--									<el-checkbox v-model="checkAll" @change="bindCheckAll">全选</el-checkbox>-->
								</label>
								<p class="t1">货品信息</p>
								<p class="t2">店铺名</p>
								<p class="t3">采购价</p>
								<p class="t4">卖价</p>
								<p class="t5">谈价状态</p>
					<!-- 			<p class="t7" :class="searchData.sales_sort==1?'up':searchData.sales_sort==2?'down':''" @click="bindSort">
									销量
									<i class="iconfont icon-sanjiao up"></i>
									<i class="iconfont icon-sanjiao down"></i>
								</p> -->
								<p class="t5">满足谈价日期</p>
							</div>
							<ul class="goods_list">
								<el-checkbox-group v-model="checkList" @change="bindCheckList">
									<li v-for="(item,index) in list" :key="index">
										<div class="check_box">
<!--                      <el-checkbox :label="item.id" :key="item.id" :checked="item.checked"></el-checkbox>-->
                    </div>
										<div class="goods_info">
											<div class="img_box"><img class="coverimg" :src="item.img" /></div>
											<div class="con_box">
												<p class="title">{{item.name}}</p>
												<p class="nums">ID：{{item.goods_id?item.goods_id:'-'}}</p>
												<p class="nums">档口：{{item.dangkou?item.dangkou.full_txt:'-'}}</p>
											</div>
										</div>
										<div class="dp_name">{{item.shop.shop_name}}</div>
										<p class="price1">￥{{item.purchase_price}}</p>
										<p class="price2">￥{{item.discount_price}}</p>
                    <div>
                      <!-- 商品状态 -->
                      <p class="status fail" v-if="item.dicker_status == 1">待谈价</p>
                      <p class="status sales" v-if="item.dicker_status == 2">已谈价</p>

<!--                      <p class="status xiajia" v-if="item.check_status == 3 && item.status == 2">已删除</p>-->
<!--                      <p class="status fail" v-if="item.check_status != 3">{{item.check_status_text}}</p>-->

                    </div>
										<!-- <p class="sales_num">{{item.product?item.product.sales_num:"0"}}</p> -->
                    <div>
                      <p class="time">{{item.dicker_check_time}}</p>
                      <p style="margin-left: 15px;">
                        <span>日:{{item.dicker_check_msg.sale_day}}</span>&nbsp;
                        <span>周:{{item.dicker_check_msg.sale_week}}</span>&nbsp;
                        <span>月:{{item.dicker_check_msg.sale_month}}</span>
                      </p>

                    </div>

										<div class="last">
											<a v-if="item.dicker_status == 1" class="guanlian" @click="onDicker(item)">谈价</a>
											<p v-if="item.dicker_status == 2" >
												<a class="guanlian" @click="onDickerHistory(item)">谈价历史</a>
											</p>
										</div>
									</li>
								</el-checkbox-group>
								<li class="nodata" v-if="page_data.total == 0">暂无数据</li>
							</ul>
						</div>
						<Pagination :total="page_data.total" :page.sync="page_data.page" :limit.sync="page_data.limit" @pagination="getList" />
					</div>
				</div>
			</div>


      <!-- 谈价弹窗 -->
      <el-dialog title="设置谈价" @close="cancel()" :visible.sync="dialog.dickerDialog" width="25%" center >
        <div class="opts_wrap">
          <div class="opts_line">
            <p class="txt">
              <span class="label">成本价：</span>
              <span class="val">
                <el-input class="w290" v-model="formIn.purchase_price" type="number" placeholder="请输入成本价">
                </el-input>

						</span>
            </p>
          </div>
          <div class="btn_wrap">
            <p class="btn" @click="bindSubmit()">确定</p>
            <p class="btn" @click="cancel()">关闭</p>
          </div>
        </div>
      </el-dialog>

      <!-- 谈价历史弹窗 -->
      <el-dialog title="谈价历史" :visible.sync="dialog.dickerHistoryDialog">
        <el-table :data="dickerHistory" max-height="400px">
          <el-table-column prop="create_time" label="时间" align="center"></el-table-column>
          <el-table-column prop="cost_old" label="谈价前" align="center"></el-table-column>
          <el-table-column prop="cost_new" label="谈价后" align="center"></el-table-column>
        </el-table>
      </el-dialog>

		</div>
		<myFoot></myFoot>
	</div>
</template>

<script>
import Vue from 'vue';
import { mapActions,mapState,mapMutations } from "vuex";
import { Loading } from 'element-ui';
import myHead from '../../components/myHead/myhead';
import myFoot from '../../components/myFoot/myfoot';
import Pagination from '../../components/pagination/pagination';
import ucMenu from '../../components/ucenterMenu/ucmenu';
import pricePloy from '../../components/pricePloy/pricePloy';
export default {
	name: "ucenterGoods",
	components:{
		myHead,myFoot,ucMenu,Pagination,pricePloy
	},
	data() {
		return {
			isLoading: false,
			shopTypeList: [],
			shopList: [],
			cateList: [],
			dangkouList: [],
			checkAll: false,
			checkList: [],
			searchData:{
				sales_sort:0
			},
      basicConfig:{
        day:0,
        week:0,
        month:0,
      },
			state:0,
			queue_num: 0,
			list:[],
			page_data: {
				limit: 10,
				page: 1,
				total:0,
			},
			dialog: {
				editPriceDialog:false,
        dickerDialog:false,
        dickerHistoryDialog:false,

			},
			itv_auto_refresh:0,
      formIn:{purchase_price:0},
      dickerHistory:[]
			
		}
	},
	created() {
		this.initFilterData()
		this.getList()
		this.autoRefresh()
	},
	destroyed() {
		if(this.itv_auto_refresh){
			clearInterval(this.itv_auto_refresh)
		}
	},
	methods: {
		...mapActions({
			getGoodsFilterData:"ucenter/getGoodsFilterData",
			getGoodsList:"ucenter/getGoodsListSecond",
			opSaveSalesConfig:"ucenter/opSaveSalesConfig",
			opBatOffSale:"ucenter/opBatOffSale",
			opProductDel:"ucenter/opProductDel",
      opProductDicker:"ucenter/opProductDicker",
			opBatProductEdit:"ucenter/opBatProductEdit",
			opSyncProduct:"ucenter/opSyncProduct",
      getProductDickerHistory:"ucenter/getProductDickerHistory",
		}),
		bindSort(){
			let i = this.searchData.sales_sort
			this.searchData.sales_sort = i==0?1:i==1?2:i==2?0:1
			this.getList()
		},
		initFilterData(){
			this.getGoodsFilterData({data:this.searchData,success:(res)=>{
				if(res.code===200){
					// console.log('get goods filter data',res)
					this.shopList = res.data.shop_list
					this.cateList = res.data.cate_list
					this.shopTypeList = res.data.shop_types
					this.dangkouList = res.data.dangkou_list
				}
			}})
		},
		onShopChange(){
			this.initFilterData()
		},
		//重置搜索
		resetSearch(){
			this.searchData = {}
			this.checkAll = false
			this.checkList = []
			this.onShopChange()
			this.getList()
		},
		//刷新列表
		refresh(){
			this.page_data.page = 1
			this.list = []
			this.checkAll = false
			this.checkList = []
			this.getList()
		},
		//自动刷新
		autoRefresh(){
			let that = this
			// that.itv_auto_refresh = setInterval(function(){
			// 	that.getList(false)
			// },60000)
			
		},
		postSyncProduct(){
			this.opSyncProduct({data:{},success:(res)=>{
				if(res.code === 200){
					this.getList(false)
					this.$message({message: res.msg,type: 'success',});
				}else{
					this.$message({message: res.msg,type: 'error',});
				}
			}})
		},
    //保存基本配置
    saveBasicConfig(){
      let that = this
      if(that.basicConfig.day <= 0 || that.basicConfig.week <= 0 || that.basicConfig.month <= 0){
        that.$message({message: '请设置正确的销量配置',type: 'error'})
        return;
      }
      that.$confirm('确认保存配置吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then((res) => {
        let param = that.basicConfig
        let loadingInstance = Loading.service({fullscreen:true,text:'保存中...'});
        that.opSaveSalesConfig({data:param,success:(res)=>{
            // console.log(res)
            loadingInstance.close();
            if(res.code == 200){
              that.$message({
                type: 'success',
                message: res.msg
              })
              that.refresh()
            }else{
              that.$message({
                type: 'error',
                message: res.msg
              })
            }
          }})
      }).catch((res) => {
        console.log(res)
      });
    },
		//获取商品列表
		getList(showLoading = true){
			if(showLoading) this.isLoading = true
			let param = {limit:this.page_data.limit,page:this.page_data.page,state:this.state}
			Object.assign(param, this.searchData)
			// console.log(param)
			this.getGoodsList({data:param,success:(res)=>{
				if(res.code===200){
					// console.log('get goods list',res)
					this.list = res.data.data
					this.page_data.total = res.data.total
					this.queue_num = res.data.queue_num
          this.basicConfig = res.data.salse_config
				} else {
					this.$message({message: res.msg,type: 'error',});
				}
				this.isLoading = false
			}})
		},
		//切换商品状态
		bindSate(e){
			this.state = e
			this.getList()
		},
		//全选
		bindCheckAll(){
			let checkAll = this.checkAll
			let list = this.list
			let checkList = []
			if(checkAll){
				for(var i in list){
					if(checkAll){
						checkList.push(list[i].id)
					}
				}
			}
			this.checkList = checkList
		},
		//单项选
		bindCheckList(){
			let checkList = this.checkList
			if(checkList.length == this.list.length){
				this.checkAll = true
			}else{
				this.checkAll = false
			}
		},




    //关闭谈价
    cancel(){
      let that = this
      that.formIn = {purchase_price:0}
      that.dialog.dickerDialog=false
    },
    // 设置谈价-弹窗
    onDicker(val){
      let that = this
      if(!val){
          that.$message({
            type: 'warning',
            message: '请选择执行操作的商品'
          })
          return
      }else{
        that.formIn.dd_product_id = val.id
        that.formIn.purchase_price = val.purchase_price
      }
      that.dialog.dickerDialog=true
    },

    // 设置谈价-保存
    bindSubmit() {
      let that = this
      if(!that.formIn.dd_product_id){
        that.$message({
          type: 'warning',
          message: '请选择要操作的商品'
        })
        return
      }
      if(!that.formIn.purchase_price){
        that.$message({
          type: 'warning',
          message: '请设置新的成本价'
        })
        return
      }

      that.formIn.purchase_price = Number(that.formIn.purchase_price).toFixed(2);

      that.$confirm('确认将该商品成本价修改为'+ that.formIn.purchase_price +'吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then((res) => {
        let param = {id:that.formIn.dd_product_id,purchase_price:that.formIn.purchase_price}
        let loadingInstance = Loading.service({fullscreen:true,text:'改价中...'});
        that.opProductDicker({data:param,success:(res)=>{
            loadingInstance.close();
            if(res.code == 200){
              that.$message({
                type: 'success',
                message: res.msg
              })
              that.dialog.dickerDialog=false
              that.refresh()
            }else{
              that.$message({
                type: 'error',
                message: res.msg
              })
            }
          }})
      }).catch((res) => {
        console.log(res)
      });




    },
    // 谈价历史-弹窗
    onDickerHistory(val,showLoading = true){
      if(showLoading) this.isLoading = true
      let param = {dd_product_id:val.id}
      this.getProductDickerHistory({data:param,success:(res)=>{
          if(res.code===200){
            console.log('dickerHistory',res.data)
            this.dickerHistory = res.data
            this.dialog.dickerHistoryDialog = true
          } else {
            this.$message({message: res.msg,type: 'error',});
          }
          this.isLoading = false
        }})
    },

	}
}
</script>

<style scoped>
.wrap .wrap_body{display: flex;flex-direction: column;}
.center{display: flex;flex: 1;}
.ucenter_main{padding: 28px 0 40px;display: flex;justify-content: space-between;flex: 1;min-height: 500px;}
.right_con{width: 980px;background: #fff;}
.goods_main{padding: 36px 40px 46px;}
.select_wrap{margin-top: 24px;display: flex;flex-wrap: wrap;}
.select_wrap .mr{margin-right: 15px;}
.select_wrap .w200{width: 200px;margin-top: 14px;margin-right: 15px;position: relative;}
.select_wrap .w240{width: 240px;margin-top: 14px;margin-right: 15px;position: relative;}
.title_fb{margin-top: 20px;padding-top: 10px;border-top: 1px solid #eee;}

.select_wrap .w290{width: 290px;margin-top: 14px;margin-right: 15px;position: relative;}
.select_wrap .w290:nth-child(3n){margin-right: 0;}
.select_wrap .cate-label{position: absolute;left: 5px;z-index: 1;}
.el-cascader{line-height: 34px;}
.select_wrap .label_title{width: 76px;height: 100%;font-size: 12px;color: #999;display: flex;align-items: center;padding-left: 6px;}
.tabs_wrap{width: 100%;height: 60px;border-bottom: 1px solid #eee;display: flex;}
.tabs_wrap .tab{height: 100%;padding: 30px 16px 0;position: relative;line-height: 1;cursor: pointer;font-size: 14px;color: #666;box-sizing: border-box;}
.tabs_wrap .tab.active{font-weight: bold;color: var(--red);}
.tabs_wrap .tab.active::after{content: '';width: 100%;height: 3px;background: var(--red);position: absolute;left: 0;bottom: 0;z-index: 2;}
.totals_btn{margin-top: 14px;display: flex;align-items: center;}
.totals_btn .totals{font-size: 12px;color: #333;padding-right: 16px;margin-right: 16px;line-height: 1;border-right: 1px solid #ccc;}
.list_title{width: 100%;height: 50px;background: #f9f9f9;margin-top: 10px;display: flex;align-items: center;font-size: 12px;color: #666;}
.list_title .checkall{margin-left: 20px;}
.list_title .t1{margin-left: 75px;width: 150px;}
.list_title .t2{margin-left: 30px;width: 100px;}
.list_title .t3{margin: 0 20px;width: 50px;text-align: right;}
.list_title .t4{margin: 0 20px;width: 50px;}
.list_title .t5{margin: 0 10px;width: 80px;}
.list_title .t6{margin: 0 20px;width: 60px;}
.list_title .t7{margin: 0 10px;width: 40px;cursor: pointer;position: relative;}
.list_title .t7 .icon-sanjiao{position: absolute;right: 3px;font-size: 10px;color: #ccc;}
.list_title .t7 .up{top: -3px;transform: rotate(180deg) translateX(-.5px) scale(.8);}
.list_title .t7 .down{top: 5px;transform: scale(.8);}
.list_title .t7.up ,.list_title .t7.up .up ,.list_title .t7.down ,.list_title .t7.down .down{color: var(--red);}

.goods_list li{padding: 28px 0;border-bottom: 1px solid #eee;display: flex;align-items: flex-start;font-size: 12px;}
.goods_list li .check_box{margin-left: 20px;height: 48px;display: flex;align-items: center;}
/deep/.goods_list li .check_box .el-checkbox__label{display: none !important;}
.goods_list li .goods_info{width: 207px;display: flex;margin-left: 14px;}
.goods_list li .goods_info .img_box{width: 48px;height: 48px;background-color: #eeeeee;border-radius: 6px;overflow: hidden;}
.goods_list li .goods_info .con_box{flex: 1;width: 0;margin-left: 10px;display: flex;flex-direction: column;justify-content: space-between;}
.goods_list li .goods_info .con_box .title{line-height: 16px;display: -webkit-box;-webkit-box-orient: vertical;-webkit-line-clamp: 2;overflow: hidden;}
.goods_list li .goods_info .con_box .nums{color: #999999;white-space: nowrap;}
.goods_list li .dp_name{width: 100px;color: #666666;line-height: 16px;margin-left: 30px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;}
.goods_list li .price1{text-align: right;}
.goods_list li .price1 ,.goods_list li .price2{width: 50px;margin: 0 20px;}
.goods_list li .status{width: 80px;display: flex;justify-content: flex-start;align-items: center;margin: 0 10px;}
.goods_list li .status::before{content: '';width: 8px;height: 8px;border-radius: 50%;margin-right: 6px;}
.goods_list li .status.sales::before{background: #0ea11f;}
.goods_list li .status.xiajia::before{background: #ccc;}
.goods_list li .status.fail::before{background: var(--red);}
.goods_list li .status.fail{color: var(--red);}
.goods_list li .time{width: 70px;line-height: 16px;margin: 0 20px;}
.goods_list li .sales_num{width: 30px;line-height: 16px;margin: 0 20px;}
.goods_list li .last{flex: 1;text-align: center;}
.goods_list li .last .guanlian{color: #1966ff;cursor: pointer;}
.goods_list li .last .guanlian:hover{text-decoration: underline;}
.goods_list li .last .opts{color: var(--red);cursor: pointer;margin-top: 6px;}
.goods_list li .last .opts:hover{text-decoration: underline;}
</style>
<style>
.el-cascader{width: 100%;}
.select_wrap .el-button{margin-top: 14px;}
.select_wrap .el-input--prefix .el-input__inner,.select_wrap .el-cascader .el-input .el-input__inner{padding-left: 76px !important;height: 34px;line-height: 34px;font-size: 12px !important;}
.el-select .el-input.is-focus .el-input__inner ,.el-select .el-input__inner:focus{border-color: var(--red);}
.el-select-dropdown__item.selected,.el-cascader-node.in-active-path, .el-cascader-node.is-active, .el-cascader-node.is-selectable.in-checked-path{color: var(--red);}
.select_wrap .el-input--prefix .el-input__inner:focus{border-color: var(--red);}
.el-input__prefix, .el-input__suffix{display: flex;align-items: center;}
.el-button{color: #333;border-color: #ddd;}
.el-button--small{padding: 7px 15px;}
.el-checkbox{color: #666;display: flex;align-items: center;}
.el-checkbox__inner{border-color: #ddd;width: 16px;height: 16px;}
.el-checkbox__input{display: flex;}
.el-checkbox__label{font-size: 12px;line-height: 1;}
.el-checkbox__inner::after{height: 9px;left: 5px;}
.el-checkbox__input.is-indeterminate .el-checkbox__inner::before{height: 4px;}

/*red border color*/
.el-button.is-plain:focus, .el-button.is-plain:hover ,.el-checkbox__inner:hover ,.el-checkbox__input.is-focus .el-checkbox__inner,.el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner, .el-pagination__jump .el-input__inner:focus,.el-cascader .el-input .el-input__inner:focus,.el-cascader .el-input .el-input__inner:focus, .el-cascader .el-input.is-focus .el-input__inner{border-color: var(--red);}

/*red color*/
.el-button.is-plain:focus, .el-button.is-plain:hover, .el-checkbox__input.is-checked+.el-checkbox__label, .el-pager li.active ,.el-pagination button:hover ,.el-pager li:hover{color: var(--red);}

/*red background color*/
.el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner{background-color: var(--red);}

.el-button:focus, .el-button:hover{border-color: var(--red);color: var(--red);background:#fff;}
.el-button--primary{background: var(--red) !important;color: #fff !important;border-color: #fff;}
.el-button--primary:focus, .el-button:hover{border-color: var(--red);}
.el-loading-spinner .el-loading-text{color:var(--red);}
.el-loading-spinner .path{stroke: var(--red);}

.opts_wrap{padding-top: 14px;}
.opts_wrap .opts_line{margin-top: 20px;display: flex;align-items: center;}
.opts_line .txt{width: 100%;font-size: 16px;display: flex;align-items: center;}
.opts_line .txt .label{min-width: 80px;display: inline-block;}
.opts_wrap .btn_wrap{margin: 40px auto 0 auto;display: flex;align-items: center;justify-content: center;}
.opts_wrap .btn_wrap .btn{width: 80px;height: 34px;border-radius: 4px;display: flex;justify-content: center;align-items: center;cursor: pointer;box-sizing: border-box;font-size: 14px;}
.opts_wrap .btn_wrap .btn:nth-child(1){background: var(--red);color: #fff;margin-right: 14px;}
.opts_wrap .btn_wrap .btn:nth-child(2){background: #fff;color: #333;border: 1px solid #ddd;margin-right: 14px;}
.ml8{margin-left: 8px;}
.opts_wrap .btn_wrap .btn:nth-child(3){background: #fff;color: #333;border: 1px solid #ddd;}
.w80{width: 80px;}
</style>
